/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  function new_map( $el ) {
    
    // var
    var $markers = $el.find('.marker');
    
    
    // vars
    var args = {
      zoom		: 16,
      center		: new google.maps.LatLng(0, 0),
      mapTypeId	: google.maps.MapTypeId.ROADMAP
    };
    
    
    // create map	        	
    var map = new google.maps.Map( $el[0], args);
    
    
    // add a markers reference
    map.markers = [];
    
    
    // add markers
    $markers.each(function(){
      
        add_marker( $(this), map );
      
    });
    
    
    // center map
    center_map( map );
    
    
    // return
    return map;
    
  }
  
  /*
  *  add_marker
  *
  *  This function will add a marker to the selected Google Map
  *
  *  @type	function
  *  @date	8/11/2013
  *  @since	4.3.0
  *
  *  @param	$marker (jQuery element)
  *  @param	map (Google Map object)
  *  @return	n/a
  */
  
  function add_marker( $marker, map ) {
  
    // var
    var latlng = new google.maps.LatLng( $marker.attr('data-lat'), $marker.attr('data-lng') );
  
    // create marker
    var marker = new google.maps.Marker({
      position	: latlng,
      map			: map
    });
  
    // add to array
    map.markers.push( marker );
  
    // if marker contains HTML, add it to an infoWindow
    if( $marker.html() )
    {
      // create info window
      var infowindow = new google.maps.InfoWindow({
        content		: $marker.html()
      });
  
      // show info window when marker is clicked
      google.maps.event.addListener(marker, 'click', function() {
  
        infowindow.open( map, marker );
  
      });
    }
  
  }
  
  /*
  *  center_map
  *
  *  This function will center the map, showing all markers attached to this map
  *
  *  @type	function
  *  @date	8/11/2013
  *  @since	4.3.0
  *
  *  @param	map (Google Map object)
  *  @return	n/a
  */
  
  function center_map( map ) {
  
    // vars
    var bounds = new google.maps.LatLngBounds();
  
    // loop through all markers and create bounds
    $.each( map.markers, function( i, marker ){
  
      var latlng = new google.maps.LatLng( marker.position.lat(), marker.position.lng() );
  
      bounds.extend( latlng );
  
    });
  
    // only 1 marker?
    if( map.markers.length == 1 )
    {
      // set center of map
        map.setCenter( bounds.getCenter() );
        map.setZoom( 16 );
    }
    else
    {
      // fit to bounds
      map.fitBounds( bounds );
    }
  
  }

  function changeCollapseStatus() {
    $('.menu-item span').on('click', function () {
      var text = $(this).text();
      if(text === " + "){
        $(this).text(' - ');
      } else{
        $(this).text(' + ');
     }
    });
  }

  function sentContactMail() {
    $("#contactform").submit(function(e) {

      var form = $("#contactform").serialize();

        $.ajax({
               type: "POST",
               url: '/cms/wp-admin/admin-ajax.php',
               data: {
                 action: "sendcontactrequest",
                 formdata: form
               },
               success: function(data)
               {
                  $( "div.success" ).append( "<br><div class='alert alert-success' role='alert'><strong>Gelukt!</strong> Uw bericht is succesvol verzonden! Wij nemen zsm contact met u op.</div>" );
               },
               error: function() {
                 $( "div.success" ).append( "<br><div class='alert alert-danger' role='alert'><strong>Oeps!</strong> Excuses, er is iets misgegaan. Probeer het nog een keer.</div>" );
               }
             });

        e.preventDefault(); // avoid to execute the actual submit of the form.
    });
  }

  var particulier = '';
  var nieuwverbouw = '';
  var q1awnser = '';
  var q2awnser = '';
  var q3awnser = '';
  var q4awnser = '';
  var q5awnser = '';
  var q6awnser = 0;

  $("#particulier").click(function(){
     particulier = 'Particulier'
     step1()
  });
  $("#zakelijk").click(function(){
    particulier = 'Zakelijk'
    step1()
  });

  function step1(){
    document.getElementById("subtext").innerHTML = '<strong>Wij stellen het zeer op prijs als u een paar minuten voor ons de tijd neemt om onderstaand klanttevredenheidsonderzoek in te vullen</strong> <br> <br>';
    document.getElementById("buttons").innerHTML = '<div class="col-md-6"> <button id="nieuwverbouw" class="btn btn-lg btn--primary" style="width: 100%;">Nieuw-/Verbouw</button> </div> <div class="col-md-6"> <button id="serviceonderhoud" class="btn btn-lg btn--primary" style="width: 100%;">Service & onderhoud</button> </div>';

    $("#nieuwverbouw").click(function(){
      nieuwverbouw = 'Nieuw-/Verbouw'
      step2();
    });
    $("#serviceonderhoud").click(function(){
      nieuwverbouw = 'Service & Onderhoud';
      step2();
    });
  }

  function step2(){
    document.getElementById("subtext").innerHTML = '';
    document.getElementById("buttons").innerHTML = '<div class="col-md-3"> <button id="continue" class="btn btn-lg btn--primary">Verder</button> </div>';

    var q1 = document.createElement('div');
    var q2 = document.createElement('div');
    var q3 = document.createElement('div');
    var q4 = document.createElement('div');
    var q5 = document.createElement('div');
    var q6 = document.createElement('div');

    q1.className = 'row'
    q2.className = 'row'
    q3.className = 'row'
    q4.className = 'row'
    q5.className = 'row'
    q6.className = 'row'

    q1.innerHTML = '<div class="title col-md-12"><strong>Hoe tevreden bent u over de snelheid waarmee Aannemersbedrijf Kwakkenbos op uw aanvragen reageert?</strong></div><div class="col-md-12"><input type="radio" name="q_1" value="niet tevreden"> niet tevreden</div> <div class="col-md-12"><input type="radio" name="q_1" value="aardig tevreden"> aardig tevreden</div><div class="col-md-12"><input type="radio" name="q_1" value="tevreden"> tevreden</div><div class="col-md-12"><input type="radio" name="q_1" value="zeer tevreden"> zeer tevreden</div><div class="col-md-12"><input type="radio" name="q_1" value="erg tevreden"> erg tevreden</div>';
    q2.innerHTML = '<div class="title col-md-12"><strong>Hoe tevreden bent u over de persoonlijke en telefonische besprekingen met de medewerkers van Aannemersbedrijf Kwakkenbos? (Wilt u daarbij denken aan wijze van communiceren, effectiviteit, efficiency, deskundigheid etc.)</strong></div><div class="col-md-12"><input type="radio" name="q_2" value="niet tevreden"> niet tevreden</div> <div class="col-md-12"><input type="radio" name="q_2" value="aardig tevreden"> aardig tevreden</div><div class="col-md-12"><input type="radio" name="q_2" value="tevreden"> tevreden</div><div class="col-md-12"><input type="radio" name="q_2" value="zeer tevreden"> zeer tevreden</div><div class="col-md-12"><input type="radio" name="q_2" value="erg tevreden"> erg tevreden</div>';
    q3.innerHTML = '<div class="title col-md-12"><strong>Hoe tevreden bent u over de contacten met de projectleiding en andere leidinggevende medewerkers van Aannemersbedrijf Kwakkenbos?</strong></div><div class="col-md-12"><input type="radio" name="q_3" value="niet tevreden"> niet tevreden</div> <div class="col-md-12"><input type="radio" name="q_3" value="aardig tevreden"> aardig tevreden</div><div class="col-md-12"><input type="radio" name="q_3" value="tevreden"> tevreden</div><div class="col-md-12"><input type="radio" name="q_3" value="zeer tevreden"> zeer tevreden</div><div class="col-md-12"><input type="radio" name="q_3" value="erg tevreden"> erg tevreden</div>';
    q4.innerHTML = '<div class="title col-md-12"><strong>Hoe waardeert u het eindproduct, dus: de kwaliteit van het werk en de wijze van opleveren?</strong></div><div class="col-md-12"><input type="radio" name="q_4" value="niet tevreden"> niet tevreden</div> <div class="col-md-12"><input type="radio" name="q_4" value="aardig tevreden"> aardig tevreden</div><div class="col-md-12"><input type="radio" name="q_4" value="tevreden"> tevreden</div><div class="col-md-12"><input type="radio" name="q_4" value="zeer tevreden"> zeer tevreden</div><div class="col-md-12"><input type="radio" name="q_4" value="erg tevreden"> erg tevreden</div>';
    q5.innerHTML = '<div class="title col-md-12"><strong>Wat zou Aannemersbedrijf Kwakkenbos volgens u nog kunnen verbeteren?</strong></div><div class="col-md-12"><textarea value="" class="col-md-12 form-control" rows="5" id="q_5" name="q_5"></textarea></div>';
    q6.innerHTML = '<div class="title col-md-12"><strong>Geef ons uw Rapportcijfer</strong></div> <div class="col-md-12"> <input type="radio" name="q_6" value="1"> 1&nbsp&nbsp <input type="radio" name="q_6" value="2"> 2&nbsp&nbsp <input type="radio" name="q_6" value="3"> 3&nbsp&nbsp <input type="radio" name="q_6" value="4"> 4&nbsp&nbsp <input type="radio" name="q_6" value="5"> 5&nbsp&nbsp <input type="radio" name="q_6" value="6"> 6&nbsp&nbsp <input type="radio" name="q_6" value="7"> 7&nbsp&nbsp <input type="radio" name="q_6" value="8"> 8&nbsp&nbsp <input type="radio" name="q_6" value="9"> 9&nbsp&nbsp <input type="radio" name="q_6" value="10"> 10 </div>';

    document.getElementById("body").appendChild(q1);
    document.getElementById("body").appendChild(document.createElement('br'));
    document.getElementById("body").appendChild(q2);
    document.getElementById("body").appendChild(document.createElement('br'));
    document.getElementById("body").appendChild(q3);
    document.getElementById("body").appendChild(document.createElement('br'));
    document.getElementById("body").appendChild(q4);
    document.getElementById("body").appendChild(document.createElement('br'));
    document.getElementById("body").appendChild(q5);
    document.getElementById("body").appendChild(document.createElement('br'));
    document.getElementById("body").appendChild(q6);

    $("#continue").click(function(){
      step3();
    });
  }

  function step3(){
    if(canContinue()){
      q5awnser = document.getElementById("q_5").value;
      document.getElementById("subtext").innerHTML = '<strong>Vul hieronder uw gegevens in (niet verplicht)<strong><br><br>';
      document.getElementById("buttons").innerHTML = '';
      document.getElementById("body").innerHTML = '<form method="post" name="researchform" id="researchform"></form>';

      var a1 = document.createElement('input');
      var a2 = document.createElement('input');
      var a3 = document.createElement('input');
      var a4 = document.createElement('input');
      var a5 = document.createElement('input');
      var a6 = document.createElement('input');
      var part = document.createElement('input');
      var nv = document.createElement('input');

      a1.type = "hidden";
      a2.type = "hidden";
      a3.type = "hidden";
      a4.type = "hidden";
      a5.type = "hidden";
      a6.type = "hidden";
      part.type = "hidden";
      nv.type = "hidden";

      a1.name = "q_1";
      a2.name = "q_2";
      a3.name = "q_3";
      a4.name = "q_4";
      a5.name = "q_5";
      a6.name = "q_6";
      part.name = "particulier";
      nv.name = "nieuwverbouw";

      a1.value = q1awnser;
      a2.value = q2awnser;
      a3.value = q3awnser;
      a4.value = q4awnser;
      a5.value = q5awnser;
      a6.value = q6awnser;
      part.value = particulier;
      nv.value = nieuwverbouw;

      var q1 = document.createElement('div');
      var q2 = document.createElement('div');
      var q3 = document.createElement('div');
      var q4 = document.createElement('div');

      q1.className = 'form-group'
      q2.className = 'form-group'
      q3.className = 'form-group'
      q4.className = 'form-group'

      q1.innerHTML = '<label for="companyname">Bedrijfsnaam</label><input type="text" class="form-control" name="companyname" placeholder="Bedrijfsnaam">';
      q2.innerHTML = '<label for="name">Naam</label><input type="text" class="form-control" name="name" placeholder="Naam">';
      q3.innerHTML = '<label for="telephonenumber">Telefoon</label><input type="text" class="form-control" name="telephonenumber" placeholder="Telefoon">';
      q4.innerHTML = '<label for="email">Email</label><input type="email" class="form-control" name="email" placeholder="Email">';

      document.getElementById("researchform").appendChild(q1);
      document.getElementById("researchform").appendChild(q2);
      document.getElementById("researchform").appendChild(q3);
      document.getElementById("researchform").appendChild(q4);
      document.getElementById("researchform").appendChild(a1);
      document.getElementById("researchform").appendChild(a2);
      document.getElementById("researchform").appendChild(a3);
      document.getElementById("researchform").appendChild(a4);
      document.getElementById("researchform").appendChild(a5);
      document.getElementById("researchform").appendChild(a6);
      document.getElementById("researchform").appendChild(part);
      document.getElementById("researchform").appendChild(nv);

      document.getElementById("researchform").appendChild(document.createElement("br"));
      var button = document.createElement("div");
      button.className = 'col-md-12';
      button.innerHTML = '<button type="submit" name="submitResearchForm" class="btn btn-lg btn--primary">Afronden</button>';
      document.getElementById("researchform").appendChild(button);

      $("#researchform").submit(function(e) {
        step4(e);
      });
    }
  }

  function step4(e){
    var form = $("#researchform").serialize();

    $.ajax({
        type: "POST",
        url: '/cms/wp-admin/admin-ajax.php',
        data: {
            action: "sendform",
            formdata: form
        },
        success: function(data)
        {
          //console.log('succes');
        },
        error: function() {
          //console.log('failure');
        }
    });
    e.preventDefault();
    window.location.href = '/bedankt/';
  }

  function canContinue(){
      var isChecked = false;
      document.getElementsByName("q_1").forEach(function(element) {
          if(element.checked){
              isChecked = true;
              q1awnser = element.getAttribute("value");
          }
      });
      if(isChecked){
          isChecked = false;
      } else {
          return false;
      }
      document.getElementsByName("q_2").forEach(function(element) {
          if(element.checked){
              isChecked = true;
              q2awnser = element.getAttribute("value");
          }
      });
      if(isChecked){
          isChecked = false;
      } else {
          return false;
      }
      document.getElementsByName("q_3").forEach(function(element) {
          if(element.checked){
              isChecked = true;
              q3awnser = element.getAttribute("value");
          }
      });
      if(isChecked){
          isChecked = false;
      } else {
          return false;
      }
      document.getElementsByName("q_4").forEach(function(element) {
          if(element.checked){
              isChecked = true;
              q4awnser = element.getAttribute("value");
          }
      });
      if(isChecked){
          isChecked = false;
      } else {
          return false;
      }
      document.getElementsByName("q_6").forEach(function(element) {
          if(element.checked){
              isChecked = true;
              q6awnser = element.getAttribute("value");
          }
      });
      if(isChecked){
          isChecked = false;
      } else {
          return false;
      }
      return true;
  }

  function unMuteVideo() {
    $("#unmute-video").click( function (){
        if( $(this).find('i').hasClass('mdi-music-off') )
        {
          $("video").prop('muted', false);
          $(this).find('i').removeClass('mdi-music-off');
          $('.video-container iframe').attr('src', 'https://player.vimeo.com/video/265556780?background=1&muted=0');
          $(this).find('i').addClass('mdi-music');
        } else {
          $(this).find('i').removeClass('mdi-music');
          $('.video-container iframe').attr('src', 'https://player.vimeo.com/video/265556780?background=1');
          $(this).find('i').addClass('mdi-music-off');
        }
    
    });
  }

  function playVideoOnIos() {
    //var isIOS = /iPad|iPhone|iPod/.test(navigator.platform);
    var isIOS = !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);

    if (isIOS) {

        var canvasVideo = new CanvasVideoPlayer({
            videoSelector: '#myVideo',
            canvasSelector: '.canvas',
            timelineSelector: false,
            autoplay: true,
            makeLoop: false,
            pauseOnClick: false,
            audio: false
        });

        $('#myVideo').hide();

    }else {

        // Use HTML5 video
        document.querySelectorAll('.canvas')[0].style.display = 'none';

    }
  }

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        changeCollapseStatus();
        sentContactMail();
        // JavaScript to be fired on all pages
        $("#menu-toggle").click(function(e) {
          e.preventDefault();
          $("#wrapper").toggleClass("toggled");
        });
        $('#nav-icon1,#nav-icon2,#nav-icon3,#nav-icon4').click(function(){
          $(this).toggleClass('open');
        });
        if( $("row--usps").length ) {
          $( ".row--usps" ).last().addClass("last");
        }
        if( $('#slider__project').length ) {
          $('#slider__project').on('slid.bs.carousel', function() {
            currentIndex = $('div.active').index() + 1;
           $('.slide__counter').html(''+currentIndex+'');
        });
        }

        var mess_content = $('.row--project');
        mess_content.each(function(){
          if ($(this).find('.col-md-4 p').text().length > 100) {
              var obrtext = $(this).find('.col-md-4 p').text().substr(0,100) ;
              var link  = $(this).find('a').attr('href');
              $(this).find('.col-md-4 p').html(obrtext + "...<br><br><a href='"+link+"' class='btn btn--primary'>Lees meer</a>") ;
          }
        });
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
        unMuteVideo();
        playVideoOnIos();
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'contact': {
      init: function() {
        // JavaScript to be fired on the about us page
        $('.map').each(function(){
            map = new_map( $(this) );
        });
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
